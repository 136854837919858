import React from "react";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import SEO from "../components/seo";
import { Container } from "../pages/index";

export default function Template({ data }) {
  return (
    <StaticQuery
      query={graphql`
        query($path: String!) {
          markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
              path
              title
              className
            }
          }
        }
      `}
      render={() => {
        const { markdownRemark } = data;
        const { frontmatter, html } = markdownRemark;
        return (
          <MarkdownPageContainer className={frontmatter.className}>
            <SEO />
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </MarkdownPageContainer>
        );
      }}
    />
  );
}

export const MarkdownPageContainer = styled(Container)`
  max-width: 988px;
  padding-bottom: ${(props) => props.theme.space[8]}px;
  h1 {
    margin: ${(props) => props.theme.space[5]}px 0;
  }
  h2 {
    margin: ${(props) => props.theme.space[3]}px 0;
  }
  p {
    margin: 0 0 ${(props) => props.theme.space[2]}px 0;
  }
  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin-bottom: ${(props) => props.theme.space[5]}px;
    li {
      padding-left: ${(props) => props.theme.space[3]}px;
      font-size: ${(props) => props.theme.fontSizes[2]}px;
      color: ${(props) => props.theme.colors.white};
      position: relative;
      margin-top: ${(props) => props.theme.space[1]}px;
      ::before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${(props) => props.theme.colors.primary};
        position: absolute;
        left: 0;
        top: 5px;
      }
    }
  }
  ol {
    margin-left: ${(props) => props.theme.space[4]}px;
    margin-bottom: ${(props) => props.theme.space[5]}px;
    li {
      padding-left: 0;
      margin-top: ${(props) => props.theme.space[1]}px;
      &::before {
        content: "";
        width: 0;
        height: 0;
      }
    }
  }
`;
